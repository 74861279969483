import request from "../utils/axios"

export const wechatLogin = (code, scope='snsapi_base') => {
    return request({
        url: '/wechat/oauth/login',
        method: 'POST',
        data: {code, scope}
    })
}

export const signatureConfig = (url) => {
    return request({
        url: '/wechat/js/sdk/config',
        method: 'POST',
        data: {url}
    })
}
