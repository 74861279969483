<template>
  <div>

    <van-overlay :show="show" >
      <!--    <div class="loading-wrap">
            <van-loading type="spinner" size="100" color="#f2733c" vertical>授权中</van-loading>
          </div>-->
      <div class="wrapper">
        <div class="block">
          <van-loading type="spinner" size="100"  vertical>授权中</van-loading>
        </div>
      </div>

    </van-overlay>


  </div>
</template>

<script>
import Vue from 'vue';
import {Loading, Overlay, Toast} from 'vant';
import {wechatLogin} from '@/api/wechat'
//import wechat from "@/utils/wechat";
import {getUserToPath, setOpenId} from "@/utils/helper";

Vue.use(Loading);
Vue.use(Overlay);
Vue.use(Toast);
export default {
  name: "Oauth",
  data() {
    return {
      show: true
    }
  },

  created() {
    const {code} = this.$route.query
    const scope = 'snsapi_base'
    // todo 处理授权出错情况
    wechatLogin(code, scope).then(res=>{
      console.log(res)
      if (res.code === 200){
        setOpenId(true)
        this.redirectBack()
      }else {
        Toast.fail((res.message|| '用户授权失败'));
      }
    }, error => {
      console.log(error)
      Toast.fail('用户授权出错');
    })

  },
  methods:{
    redirectBack(){
      this.$router.replace(getUserToPath())
    }

  }

}
</script>

<style scoped>
.van-overlay{
  background-color: rgba(255, 255, 255, 0.1);
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 160px;
  height: 160px;
  /*background-color: #fff;*/
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

</style>
